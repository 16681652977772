
import { defineComponent } from "vue";

export default defineComponent({
  name: "BasicLoading",
  props: {
    msg: String,
    color: String,
  },
  computed: {
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
  },
});
